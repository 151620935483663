<template>
  <div class="container">
    <breadcrumb
      :title="$route.query.line || '设备对接'"
      :childTitle="showCompsType.nav"
      @goBackPage="goBackPage"
    >
      <div v-if="!previewInfo.isPreview">
        <a-button
          @click.native="handleOpenChangeRecord"
          id="equipment_equip_setting_change_record"
          class="mr20"
        >
          修改记录
        </a-button>
        <a-button
          @click.native="saveEquipSetting"
          :disabled="!isDisplaySave || btnDisable"
          type="primary"
          id="equipment_setting_save_btn"
          ><a-icon type="save" />保存设置</a-button
        >
      </div>
      <div v-if="previewInfo.isApply">
        <a-button
          @click.native="saveEquipSetting"
          :disabled="!isDisplaySave || btnDisable"
          type="primary"
          id="equipment_setting_save_btn"
          ><a-icon type="save" />应用设置</a-button
        >
      </div>
    </breadcrumb>
    <div class="wrap">
      <component
        :is="showCompsType.comps"
        :ref="`${showCompsType.comps}Ref`"
      ></component>
    </div>
    <m-lock-modal
      :visible.sync="isShowVisible"
      :status.sync="lockStatus"
      :verifyPassword="verifyPassword"
      :tempStatus.sync="tempStatus"
      @getLockPassword="handleGetLockPassword"
      :lineName="$route.query.line"
    ></m-lock-modal>
    <!-- 修改记录弹窗 -->
    <m-change-record-modal
      :visible.sync="showRecordModal"
      operate_equipment="glassEquip"
      @success="handleSuccess"
      ref="recordModalRef"
    />
  </div>
</template>

<script>
import { getLockStatus } from '@/apis/productionLineLock'
import breadcrumb from '@/components/topBreadCrumb.vue'
import store from '@/store'
import { compareDiffForm, genFinalRecord } from '@/util/commonFuncs.js'
import {
  glassEquipFormTextObj,
  glassEquipFormValueObj,
} from '@/util/operateRecordConfigure/glassEquipConfigure'
import { saveDataJsonToOssForRecord } from '@/util/saveFile'
import MChangeRecordModal from '@/views/equipment/component/m-change-record-modal.vue'
import MLockModal from '@/views/equipment/component/m-lock-modal.vue'

const infoMap = {
  glassEquip: {
    nav: '玻璃切割机',
    comps: 'glassEquip',
  },
}

const compsIndicator = (equip) => {
  return infoMap[equip] || infoMap['glassEquip']
}

// 引入component文件下的所有vue组件文件, 组件名由路由路径参数决定 equipType
const comps = {}
const files = require.context('./component', false, /\.vue$/)
files.keys().forEach((key) => {
  comps[key.replace(/(\.\/|\.vue)/g, '')] = files(key).default
})
export default {
  components: {
    breadcrumb,
    MLockModal,
    ...comps,
    MChangeRecordModal,
  },
  data() {
    return {
      queryInfo: {},
      btnDisable: false,
      lockStatus: 1, // 锁状态
      verifyPassword: true,
      lockPassword: '',
      tempStatus: 1, //默认状态
      isShowVisible: false,
      showRecordModal: false,
    }
  },
  computed: {
    isDisplaySave() {
      return this.$store.state.equipSetting.isDisplaySaveButton
    },
    // 根据equipType参数值 决定需要显示的组件 和 需要显示的面包屑
    showCompsType() {
      return compsIndicator(this.$route.query.equipType)
    },
    previewInfo() {
      return this.$store.state.equipEnvironment.previewInfo
    },
  },
  methods: {
    async handleGetLockPassword() {
      await this.saveEquipSetting()
    },
    saveDataJsonToOssForRecordFn(record, option, cb) {
      saveDataJsonToOssForRecord(record, 'equipment_docking', option, cb)
    },
    compareGlassEquip(current, ori) {
      const record = []
      const compareMsg = [
        {
          title: '',
          keys: [
            'glass_model',
            'contour_size_recoup',
            'cut_buffer_distance',
            'use_category_folder',
          ],
        },
      ]
      const glassModelValueObj = {}
      this.$refs[`${this.showCompsType.comps}Ref`].processFileTypeList.forEach(
        (item) => {
          glassModelValueObj[item.glass_model] = item.modelName
        }
      )

      // 比较form的不同项
      const formChangeArr = compareDiffForm(
        current,
        ori,
        compareMsg,
        glassEquipFormTextObj,
        {
          glass_model: glassModelValueObj,
          ...glassEquipFormValueObj,
        }
      )

      if (formChangeArr.length) {
        record.push(...formChangeArr)
      }

      return record
    },
    // 生成最终的操作记录
    genFinalRecord(data, callback = null) {
      // const currentData = this.$refs[`${this.showCompsType.comps}Ref`].formData
      const currentData = data
      const oriData = this.$refs[`${this.showCompsType.comps}Ref`].oriFormData
      const finalRecord = this.compareGlassEquip(currentData, oriData)

      if (finalRecord.length) {
        this.saveDataJsonToOssForRecordFn(
          {
            key: '玻璃切割机',
            value: finalRecord,
          },
          {
            id: this.$route.query.setting_id,
            operate_equipment: 'glassEquip',
            save_data: data,
            setting_id: this.$route.query.setting_id,
            operatorPhone: this.previewInfo.operatorPhone,
          },
          () => {
            if (!this.previewInfo.isApply) {
              // 重置 数据
              this.getEquipSetting()
            }
            callback && callback()
          }
        )
      }
    },
    // 保存组件页面设置
    saveEquipSetting() {
      // 判断锁定状态，打开弹窗
      // 根据获取的状态 锁定状态
      this.lockStatus = this.tempStatus
      const { equipType, setting_id } = this.$route.query
      const data = this.$refs[`${this.showCompsType.comps}Ref`].getDealSetting()
      this.btnDisable = true
      this.$store.commit('setCurrentProductLineName', this.$route.query.line)
      this.$store.dispatch('equipSetting/saveSetting', {
        equipType,
        setting_id,
        ...store.state.saveInfo,
        platform: 'bluen',
        data,
        cb: () => {
          this.isShowVisible = false
          store.commit('setSaveInfo', {})
          this.genFinalRecord(data)
        },
        cbSaveSuccess: (res) => {
          let recordData = {
            key: '保存设置',
            operateArr: [
              {
                key: '生产线设置',
                dataArr: [
                  {
                    type: 'form',
                    current: {
                      phone: res.data.phone,
                      time: new Date().toLocaleString(),
                      id: store.state.userInfo.id,
                      ip: res.data.request_ip,
                    },
                    ori: {
                      phone: '',
                      time: '',
                      id: '',
                      ip: '',
                    },
                    compareMsg: [
                      {
                        title: '',
                        keys: ['phone', 'time', 'id', 'ip'],
                      },
                    ],
                    formTextObj: {
                      phone: '手机号',
                      time: '保存时间',
                      id: '用户id',
                      ip: '物理地址',
                    },
                    formValueObj: {},
                  },
                ],
              },
            ],
          }
          store.commit('setSaveInfo', {})
          genFinalRecord(recordData, 'equipment_docking', '', () => {})
        },
        cbSaveFail: (res) => {
          if (store.state.saveInfo.phone) {
            this.$message({
              message: res.msg,
              type: 'error',
              duration: 1500,
              offset: 70,
            })
          }
          store.commit('setSaveInfo', {})
          this.showSaveModal()
        },
        finalCb: () => {
          store.commit('setSaveInfo', {})
          this.btnDisable = false
        },
      })
    },
    showSaveModal() {
      this.lockStatus = this.tempStatus
      this.verifyPassword = true
      this.isShowVisible = true
      return
    },
    // 获取对应组件页面的值
    getEquipSetting() {
      const { equipType, setting_id } = this.$route.query
      this.$store.dispatch('equipSetting/getSettingInfo', {
        equipType: equipType,
        setting_id: setting_id,
      })
    },
    goBackPage() {
      this.$store.commit('equipSetting/changeSavaButtonStatus', false)
      let path = ''
      if (this.showTiangong) {
        path = '/equipment?from=tiangong'
      } else {
        path = '/equipment'
      }
      if (this.previewInfo.isPreview) {
        path = `${path}-preview`
      }
      this.$router.push(path)
    },
    handleGetLockStatus() {
      //TODO 进入页面，获取生产线设置锁的状态
      getLockStatus().then((res) => {
        // 拿到的锁状态赋值，当前是锁定还是解锁状态
        this.lockStatus = res?.data?.is_lock || 0
        this.tempStatus = res?.data?.is_lock || 0
      })
    },
    // 打开修改记录弹窗
    handleOpenChangeRecord() {
      this.showRecordModal = true
    },
    // 还原成功的回调
    handleSuccess(data) {
      this.genFinalRecord(data, () => {
        this.$refs['recordModalRef']?.getData({ isReset: true })
      })
    },
  },
  created() {
    this.handleGetLockStatus()
  },
  mounted() {
    this.getEquipSetting()
  },
  destroyed() {
    // 退出页面将保存按钮置灰
    this.$store.commit('equipSetting/changeSavaButtonStatus', false)
  },
}
</script>

<style scoped lang="less">
/deep/.el-input {
  width: 50px;
}

.container {
  // min-width: 500px;
  .wrap {
    margin: 16px;
    background: #fff;
  }
}
</style>
